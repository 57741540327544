import type { MetaDescriptor } from "@remix-run/node";

export const generateMetaTags = ({
  title,
  description,
  additionalTags = [],
}: {
  title: string;
  description: string;
  additionalTags?: Array<MetaDescriptor>;
}) =>
  [
    { charSet: "utf-8" },
    { content: "width=device-width, initial-scale=1", name: "viewport" },
    // Primary Meta Tags
    { title },
    { content: title, name: "title" },
    {
      content: description,
      name: "description",
    },
    // Open Graph / Facebook
    { content: "website", property: "og:type" },
    { content: "https://app.carrierassure.com/", property: "og:url" },
    { content: title, property: "og:title" },
    {
      content: description,
      property: "og:description",
    },
    {
      content: "https://app.carrierassure.com/_static/preview.png",
      property: "og:image",
    },
    // Twitter
    { content: "summary_large_image", property: "twitter:card" },
    { content: "https://app.carrierassure.com/", property: "twitter:url" },
    { content: title, property: "twitter:title" },
    {
      content: description,
      property: "twitter:description",
    },
    {
      content: "https://app.carrierassure.com/_static/preview.png",
      property: "twitter:image",
    },
    // Favicons
    {
      content: title,
      name: "apple-mobile-web-app-title",
    },
    { content: title, name: "application-name" },
    { content: "#051c2c", name: "msapplication-TileColor" },
    {
      content: "https://app.carrierassure.com/_static/browserconfig.xml?v=2",
      name: "msapplication-config",
    },
    { content: "#051c2c", name: "theme-color" },
    ...additionalTags,
  ] satisfies Array<MetaDescriptor>;
